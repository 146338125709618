import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import styled from "styled-components"

import {Content} from '../styles/global-styles';
import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Obfuscate from 'react-obfuscate';


const Container = styled.div`
    width: 100%;
    // padding-top: 60px;
    text-align: center;
    // background: #ACD433;
    // background: #2c466b;
    // color: wheat;
    // margin-top: 15px;
    margin: 0 auto;
    @media screen and (max-width: 1220px) {
        margin-top: 0;
    }
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    // padding-bottom: 60px;
    
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
    
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
    
    
`

const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;
    
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

// const Content = styled.div`
//     margin: 0 auto;
//     margin-top: 40px;
//     max-width: 90%;
    
//     p { 
        
//         font-size: 20px;
//         line-height: 1.4;
//         margin-top: 14px;
//         font-weight: 400;
//         white-space: pre-wrap;
//         margin-bottom: 14px;
//         text-align: left;
//     }
// `

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
    
    @media screen and (max-width: 575px) {
        width: auto;
        margin: 10px;
        margin-bottom: 25px;
    }
    
    @media screen and (max-width: 767px) {
        width: auto;
        // margin: 10px;
        // margin-bottom: 25px;
    }
`

const ArticlesWrapper = styled.div`
    margin-top: 16px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 0;
    
    @media screen and (max-width: 575px) {
        width: 120px;
        height: 120px;
        margin-bottom: 0;
    }
    
    @media screen and (max-width: 767px) {
        // width: 120px;
        // height: 120px;
        // margin-bottom: 10px;
    }
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 0;
    
    @media screen and (max-width: 575px) {
        font-size: 18px;
    }
    
    @media screen and (max-width: 767px) {
        // font-size: 18px;
    }
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`

const ArticlesContent = styled(Content)`
    margin-top: 56px;
    overflow: hidden;
    padding-top: 0;
    
    @media screen and (max-width: 767px) {
        margin-top: 40px;
        padding-top: 0;
    }
`

const StyledButton = styled(Button)`
    margin: 8px;
    width: 500px;
    display: block;
    font-size: 20px;
`

const LogoWrapper = styled.div`
    position: relative;
    user-select: none;
    width: max-content;
    margin: 0 auto;

  
  a {
    &:hover {
      text-stroke: initial;
      -web-kit-text-stroke: initial;
      webkit-text-stroke-width: unset;
      -webkit-text-stroke-color: unset;
      -webkit-text-stroke-width:0;
    }
  }
`


const Logo = styled.h1`
  position: relative;
  font-size: 64px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  color: rgba(44,70,107,1);
  a {
    text-stroke: initial;
    -web-kit-text-stroke: initial;
    webkit-text-stroke-width: unset;
    -webkit-text-stroke-color: unset;
  }
  
  
  .trademark {
    position: absolute;
    font-size: 24px;
    top: 10px;
  }
`

const Tagline = styled.h2`
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  text-align: right;
  color: #ff8000;
  

  // color: #ff8000;
  // -webkit-text-stroke-width: 1px;
  // text-stroke: 1px;
  // -webkit-text-stroke-color: #ff8000;
`

const Address = styled.a`
    text-transform: lowercase;
    font-size: 18px;
    
    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
`

const AddressCoded = styled(Obfuscate)`
    text-transform: lowercase;
    font-size: 18px;
    
    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
`

const FurtherContent = styled.div`
    margin-top: 20px;
`




const ContactContent = () => {

    const {t} = useI18next();
    const context = React.useContext(I18nextContext);
    const language = context.language;
      
    const data = useStaticQuery(graphql`
    query {
        sandy: file(relativePath: {eq: "sandy-vaci.jpg"}, publicURL: {}) {
            id
            publicURL
        }
        frigyes: file(relativePath: {eq: "endersz--frigyes.jpg"}, publicURL: {}) {
            id
            publicURL
        }
        ourTeam: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-team)/"  }} sort: {fields: frontmatter___weight, order: DESC}) {
          edges {
            node {
              frontmatter {
                name
                nick
                titles
                email
                language
              }
              html
              id
            }
          }
        }
    }
  `)
  
  let team = data.ourTeam.edges
  let teamFilteredByLanguage = team.filter(member=>member.node.frontmatter.language == language)
  
  
    return (
        <Container>
            <ContentWrapper >
                <SectionHeader title={t("CONTACT.CONTACT")} withBackground={false}/>
                <ArticlesContent>
                    <ContentInner>
                        <AvatarsContainer>
                                {
                                    teamFilteredByLanguage.map((member, index)=>{
                                        if (member.node.frontmatter.nick === "sandy") {
                                            return (
                                                <AvatarWrapper key={index}>
                                                    {/* <Address href={`mailto: ${member.node.frontmatter.email}`}><StyledAvatar src={member.node.frontmatter.nick == "sandy" ? data.sandy.publicURL : data.frigyes.publicURL} /></Address>
                                                    <Address href={`mailto: ${member.node.frontmatter.email}`}><Name>{member.node.frontmatter.name}</Name></Address> */}
                                                    <AddressCoded email={member.node.frontmatter.email}><StyledAvatar src={member.node.frontmatter.nick == "sandy" ? data.sandy.publicURL : data.frigyes.publicURL} /></AddressCoded>
                                                    <AddressCoded email={member.node.frontmatter.email}><Name>{member.node.frontmatter.name}</Name></AddressCoded>
                                                    <AddressCoded email={member.node.frontmatter.email} />
                                                </AvatarWrapper>
                                            )
                                        } else {
                                            return null
                                        }   
                                    })
                                }
                        </AvatarsContainer>
                        <FurtherContent>
                            {/* <Address href="mailto: info@codebreakers.co.hu">info@codebreakers.co.hu</Address> */}
                            <AddressCoded email="info@codebreakers.co.hu" />
                        </FurtherContent>
                    </ContentInner>
                </ArticlesContent>
            </ContentWrapper>
        </Container>
    )
}

export default ContactContent